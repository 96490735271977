import React, { useState } from 'react';
import Layout from "../components/layout"
import SEO from "../components/seo"
import Recaptcha from "react-google-recaptcha";
import { serialize } from 'dom-form-serializer'
import { useIntl } from "gatsby-plugin-intl"

const RECAPTCHA_KEY = '6LfE2ZIUAAAAAH3NAErYaOtpJ9BMrAZokpRH7lUS';// process.env.SITE_RECAPTCHA_KEY;

function encode(data) {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&");
}

const Contact = () => {
    const intl = useIntl()

    const [alertMessage, setAlertMessage] = useState("");

    const ref = React.createRef("recaptcha");

    const handleSubmit = event => {
        event.preventDefault()
        const form = event.target;
        const data = serialize(form)
 
        fetch("/", {
            method: "POST",
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            body: encode({
                "form-name": form.getAttribute("name"),
                ...data
            })
        })
        .then(res => {
            if (res.ok) {
                return res
            } else {
                throw new Error('Network error')
            }
        })
        .then(() => {
            form.reset()
            setAlertMessage(intl.formatMessage({ id: "CONTACT.NOTIFICATION_MESSAGE_SENT" }))
        })
        .catch(err => {
            console.error(err)
            setAlertMessage(intl.formatMessage({ id: "CONTACT.NOTIFICATION_MESSAGE_SENT_ERROR" }))
        })
    };

    return (
        <Layout>
            <SEO title="Contact" />
            <h1>{intl.formatMessage({ id: "CONTACT.TITLE" })}</h1>        
            <form id="contact" name="contact" method="post" onSubmit={handleSubmit} action="/" data-netlify-recaptcha="true" netlify="true">
                <div className="form">
                    <noscript>
                        <p>This form won’t work with Javascript disabled</p>
                    </noscript>
                    {alertMessage && (
                        <div className="Form--Alert">{alertMessage}</div>
                    )}
                    <div>
                        <label htmlFor="name">{intl.formatMessage({ id: "CONTACT.LABEL_LASTNAME" })}</label>
                        <input type="text" name="name" id="name" required/>
                    </div>
                    <div>
                        <label htmlFor="email">{intl.formatMessage({ id: "CONTACT.LABEL_EMAIL" })}</label>
                        <input type="email" name="email" id="email" required/>
                    </div>
                    <div>
                        <label htmlFor="message">{intl.formatMessage({ id: "CONTACT.LABEL_MESSAGE" })}</label>
                        <textarea name="message" id="message" rows="6" />
                    </div>
                </div>
                <div className="actions">
                    <div data-netlify-recaptcha="true"></div>
                    <Recaptcha
                        ref={ref}
                        sitekey={RECAPTCHA_KEY}
                    />
                    <button>{intl.formatMessage({ id: "CONTACT.BUTTON_SEND" })}</button>
                </div>
            </form>
        </Layout>
    );
};

export default Contact;